<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <h3>Sql veritabanı tabloları</h3>
        <h6>Tabloları zaten mevcut olan sistemlerde gerekli ek tabloların tanımlanması yeterli olacaktır. Bu durumda sql kullanım dizaynları geliştirici tarafından uygun olan tablo ve değişkenleri düzenlenebilir.</h6>
        <h6>SQL query bilgileri MSSQL yapısına uygun olarak dizayn edilmiştir. MSSQL schema bilgisi olarak "Woncology" kullanılmıştır.</h6>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'all', 'table_name': ''}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'DatabaseTables',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    SqlDatabase,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

